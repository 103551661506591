@import url('https://fonts.googleapis.com/css2?family=Parisienne&display=swap');
.text {
  font-family: 'Parisienne', cursive !important;
}

.text, 
.bg-mix {
  mix-blend-mode: difference;

}

body {
  margin: 0;
  background-color: #090c13;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

a  {
  text-decoration: none;
}

/* a, 
button, 
svg {
  cursor: url('/src/images/cursor-pointer.png'), auto !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  cursor: url('/src/images/cursor-text.png'), auto !important;
} */
